<template>
  <div>
    <b-row v-for="(value, propertyName, index) in this.jsonObject" :key="index">
      <b-col>
        <text-highlight :queries="highlightText ? highlightText : '' " highlightClass="highlight-word">{{propertyName}} : {{value}}</text-highlight>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TextHighlight from 'vue-text-highlight'

export default {
  props: {
    jsonData: { type: String, default: null },
    highlightText: { type: String, default: null }
  },
  components: {
    'text-highlight': TextHighlight
  },
  data () {
    return {
      jsonObject: {}
    }
  },
  watch: {
    jsonData (newData, oldData) {
      this.jsonObject = JSON.parse(newData)
      this.$refs.vuetable.refresh()
    }
  },
  created () {
    this.jsonObject = JSON.parse(this.jsonData)
  },
  methods: {}
}
</script>
<style>
.result-count{
  margin-top:25px;
}
.highlight-word{
  font-weight: bold;
}
</style>
